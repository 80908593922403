import httpService from '../../../helpers/http.service'

export default function (value) {
    let path = value;
    return  {
        getPositions(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getEnrollments(month_id, params) {
            return httpService.get(`reports/${path}/${month_id}/enrollments`, {params})
        },
        getVerifications(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getResumeCommission(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getLiquidations(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getPayments(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getPercentagePlans(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getResumePayments(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getMonthClose(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getInfVerify(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getConsolidateEnrollment(params) {
            return httpService.get(`reports/${path}`, {params})
        },
        getConsolidateEnrollmentDetail(params) {
            return httpService.get(`reports/${path}`, {params})
        },
    }

}
